import * as React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Col, Carousel } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faPhoneAlt,
  faPrint,
} from "@fortawesome/free-solid-svg-icons"
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import scrollTo from "gatsby-plugin-smoothscroll"
import JotformEmbed from "react-jotform-embed"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/general-styles.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div>
      <div className="top-panel">
        <div className="desktop-component">
          <Row>
            <Col>
              <div>
                <a href="tel:+8003666862" className="phone-number load-in">
                  <FontAwesomeIcon icon={faPhoneAlt} size="1x" /> 800-366-6862
                </a>
              </div>
            </Col>
            <Col>
              <StaticImage
                src="../images/pool-heating-advisory-logo.png"
                formats={["auto", "webp"]}
                alt="Pool Heating Advisory logo"
                className="load-in"
              />
            </Col>
          </Row>
        </div>
        <div className="mobile-component">
          <StaticImage
            src="../images/pool-heating-advisory-mobile-logo.png"
            formats={["auto", "webp"]}
            alt="Pool Heating Advisory logo"
            className="pool-heating-advisory-logo load-in"
          />
        </div>
        <Row>
          <Col md={5} xs={12}>
            <div className="desktop-component">
              <div className="top-panel-text-box">
                <p className="top-panel-small-text load-in">
                  Solar Pool Heating & Environmental Heat Pumps
                </p>
                <br />
                <h1 className="top-panel-large-text">
                  Double Your Swim Season
                </h1>
                <br />
                <p className="top-panel-small-text load-in">
                  A heated pool can improve your lifestyle, health and home
                  value. Why spend half the year just looking at your pool when
                  you could be swimming in it?
                </p>
                <br />
                <button
                  className="estimate-button"
                  onClick={() => scrollTo("#contact-panel")}
                >
                  Get a Free Estimate {">>"}
                </button>
              </div>
            </div>
          </Col>
          <Col md={7} xs={12}>
            <StaticImage
              src="../images/pool-heating-graphic.png"
              formats={["auto", "webp"]}
              alt="Pool Heating graphic"
              className="pool-heating-advisory-graphic load-in"
            />
          </Col>
        </Row>
        <div className="mobile-component">
          <div className="top-panel-text-box">
            <p className="top-panel-small-text load-in">
              Solar Pool Heating & Environmental Heat Pumps
            </p>
            <h1 className="top-panel-large-text">Double Your Swim Season</h1>
            <br />
            <div>
              <a href="tel:+8003666862" className="phone-number load-in">
                <FontAwesomeIcon icon={faPhoneAlt} size="1x" /> 800-366-6862
              </a>
            </div>
            <br />
            <p className="top-panel-small-text load-in">
              A heated pool can improve your lifestyle, health and home value.
              Why spend half the year just looking at your pool when you could
              be swimming in it?
            </p>
            <br />
            <button
              className="estimate-button"
              onClick={() => scrollTo("#contact-panel")}
            >
              Get a Free Estimate {">>"}
            </button>
          </div>
        </div>
      </div>
      <div className="desktop-component">
        <StaticImage
          src="../images/site-stamps.png"
          formats={["auto", "webp"]}
          alt="Pool heat pump"
          className="logos-row"
        />
      </div>
      <div className="mobile-component">
        <StaticImage
          src="../images/site-stamps-mobile.png"
          formats={["auto", "webp"]}
          alt="Pool heat pump"
          className="logos-row"
        />
      </div>
      <Carousel>
        <Carousel.Item interval={5000}>
          <div className="bg-image-panel-sph">
            <div className="carousel-overlay">
              <div className="bg-image-text">
                Solar pool heating means your pool is ready when you are.
              </div>
              <div className="bg-image-text-small">
                There's no need to plan ahead with solar ­— <br /> your pool
                stays warm day after day using free solar energy.
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <div className="bg-image-panel-heat-pumps">
            <div className="carousel-overlay">
              <div className="bg-image-text">
                Environmental heat pumps are economical, safe and trouble-free.
              </div>
              <div className="bg-image-text-small">
                Transfer heat from the air to your pool water- smart for your
                budget and for the environment.
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <div className="product-panel">
        <div className="product-box">
          <h2 className="product-panel-header">Pool Heating Options:</h2>
          <br />
          <Row>
            <Col md={6} xs={12}>
              <h3 className="product-header">Solar Pool Heating</h3>
              <p className="product-text load-in">
                Extend your swim season using the power of the sun. You'll enjoy
                warm, comfortable swimming at least two months earlier without
                pool heating and for several months longer.
              </p>
              <StaticImage
                src="../images/solar-pool-heating-panel.png"
                formats={["auto", "webp"]}
                alt="Solar pool heating panel"
                className="solar-panel-image load-in"
              />
            </Col>
            <Col md={6} xs={12}>
              <h3 className="product-header">Environmental Heat Pump</h3>
              <p className="product-text load-in">
                An environmental heat pump can extend your swimming season
                longer than any other heat pumps on the market, heating even
                when outside temperatures are in the mid to low 30s.
              </p>
              <StaticImage
                src="../images/sunwave-heat-pump.png"
                formats={["auto", "webp"]}
                alt="Pool heat pump"
                className="heat-pump-image load-in"
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="contact-panel" id="contact-panel">
        <Row>
          <Col xs={12} md={6}>
            <h2 className="contact-panel-header">Contact Us</h2>
            <StaticImage
              src="../images/inner-tube.jpg"
              formats={["auto", "webp"]}
              alt="inner tube in pool"
              className="load-in"
            />
            <br />
            <p className="contact-text load-in">
              Fill out the form posted here to learn more about our products or
              get a free estimate. We will reach out to you shortly.
            </p>
            <div className="desktop-component">
              <StaticImage
                src="../images/smiling-couple.jpg"
                formats={["auto", "webp"]}
                alt="Pool heat pump"
                className="product-image load-in"
              />
              <div className="share-row">
                <Row className="g-0">
                  <Col xs={3}>
                    <p>Share: </p>
                  </Col>
                  <Col>
                    <a
                      className="share-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:?subject=Pool%20Heating%20Advisory&amp;body=https://www.poolheatingadvisory.com"
                    >
                      <FontAwesomeIcon icon={faEnvelope} size="1x" />
                    </a>
                  </Col>
                  <Col>
                    <a
                      className="share-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://twitter.com/intent/tweet?url=https://www.poolheatingadvisory.com&amp;text=Pool%20Heating%20Advisory"
                    >
                      <FontAwesomeIcon icon={faTwitter} size="1x" />
                    </a>
                  </Col>
                  <Col>
                    <a
                      className="share-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.facebook.com/sharer/sharer.php?u=https://www.poolheatingadvisory.com&amp;title=Pool%20Heating%20Advisory"
                    >
                      <FontAwesomeIcon icon={faFacebook} size="1x" />
                    </a>
                  </Col>
                  <Col>
                    <a
                      className="share-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.poolheatingadvisory.com&amp;title=Pool%20Heating%20Advisory&amp;source=https://www.poolheatingadvisory.com"
                    >
                      <FontAwesomeIcon icon={faLinkedin} size="1x" />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href="/"
                      className="share-link"
                      onClick={() => window.print()}
                    >
                      <FontAwesomeIcon icon={faPrint} size="1x" />
                    </a>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div>
              <JotformEmbed
                src="https://form.jotform.com/213117041903140"
                className="jotform"
              />
            </div>
          </Col>
        </Row>
        <div className="mobile-component">
          <StaticImage
            src="../images/smiling-couple.jpg"
            formats={["auto", "webp"]}
            alt="Pool heat pump"
            className="product-image load-in"
          />
          <div className="share-row">
            <Row className="g-0">
              <Col xs={3}>
                <p>Share: </p>
              </Col>
              <Col>
                <a
                  className="share-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:?subject=Pool%20Heating%20Advisory&amp;body=https://www.poolheatingadvisory.com"
                >
                  <FontAwesomeIcon icon={faEnvelope} size="1x" />
                </a>
              </Col>
              <Col>
                <a
                  className="share-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://twitter.com/intent/tweet?url=https://www.poolheatingadvisory.com&amp;text=Pool%20Heating%20Advisory"
                >
                  <FontAwesomeIcon icon={faTwitter} size="1x" />
                </a>
              </Col>
              <Col>
                <a
                  className="share-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.facebook.com/sharer/sharer.php?u=https://www.poolheatingadvisory.com&amp;title=Pool%20Heating%20Advisory"
                >
                  <FontAwesomeIcon icon={faFacebook} size="1x" />
                </a>
              </Col>
              <Col>
                <a
                  className="share-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.poolheatingadvisory.com&amp;title=Pool%20Heating%20Advisory&amp;source=https://www.poolheatingadvisory.com"
                >
                  <FontAwesomeIcon icon={faLinkedin} size="1x" />
                </a>
              </Col>
              <Col>
                <a
                  href="/"
                  className="share-link"
                  onClick={() => window.print()}
                >
                  <FontAwesomeIcon icon={faPrint} size="1x" />
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
